/* eslint-disable indent */
/* eslint-disable no-shadow */

const state = {
  isFullscreen: false // 是否全屏
},

  mutations = {
    SET_IS_FULL_SCREEN: (state, isFullscreen) => {
      state.isFullscreen = isFullscreen;
    }
  },

  actions = {
    /**设置是否全屏的值 */
    setIsFullscreen ({ commit }, isFullscreen) {
      commit('SET_IS_FULL_SCREEN', isFullscreen);
    }
  };

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
