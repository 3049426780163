<!-- eslint-disable one-var -->
<!-- 弹窗组件 -->
<template>
  <el-dialog
    v-model="dialogVisible"
    class="dialog-wrap"
    :width="width"
    :top="top"
    :title="title"
    :show-close="true"
    :close-on-click-modal="clickModalClose"
  >
    <div>
      <slot name="content"></slot>
    </div>
    <!-- 模态框按钮 -->
    <template #footer>
      <span v-if="comfirmButtonShow" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button
          style="background-color: #3ea88d; color: white"
          @click="comfirm"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
/**
 * dialog 弹框公共组件
 *** @event close {Fun}} - 关闭事件
 *** @event comfirm {Fun}} - 保存事件
 */
import { computed } from 'vue';
const props = defineProps({
    //弹窗显示与隐藏 默认false
    visible: {
      type: Boolean,
      default: false
    },
    //标题
    title: {
      type: String,
      default: ''
    },
    // 弹窗的宽度 默认940px 支持 px与%
    width: {
      type: String,
      default: '840px'
    },
    // 弹窗距离顶部位置 默认15vh
    top: {
      type: String,
      default: '10vh'
    },
    //弹窗是否支持点击遮罩层关闭弹窗 默认true
    clickModalClose: {
      type: Boolean,
      default: true
    },
    //是否显示确认按钮
    comfirmButtonShow: {
      type: Boolean,
      default: true
    },
    //关闭图标
    iconLeft: {
      type: String,
      default: '90%'
    }
  }),
  emit = defineEmits(['close', 'comfirm']);

// 计算 dialog 的显示 与隐藏
// eslint-disable-next-line one-var, newline-after-var
const dialogVisible = computed({
    get: () => props.visible,
    set: (newValue) => {
      emit('close', newValue);
    }
  }),
  // 确认
  comfirm = () => {
    emit('comfirm', false);
  },
  // 取消
  cancel = () => {
    emit('close', false);
  };
</script>

<style lang="scss" scoped></style>
