import request from '@/utils/request';

//下载
export function downFileById(id) {
  window.location.href = `${process.env.VUE_APP_API}sys/fileInfo/download/${id}`;
}

//下载
export function getFileBlob(id) {
  return request({
    url: `/sys/fileInfo/download/${id}`,
    method: 'get',
    responseType: 'blob'
  });
}

//获取永久下载地址
export function getDownloadUrl(fileName) {
  return request({
    url: `/sys/fileInfo/getDownloadUrl?url=${fileName}`,
    method: 'get'
  });
}


//上传
export function uploadFile(data) {
  return request({
    url: `/sys/fileInfo/upload/`,
    method: 'post',
    data: data
  });
}
