<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div
    v-if="props.total > 0"
    style="
      margin-top: 0.1302rem;
      margin-bottom: 0.1521rem;
      display: inline-block;
      float: none;
    "
  >
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      background
      hide-on-single-page
      :page-sizes="props.pageSizes"
      :layout="props.layout"
      :total="props.total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<!---分页组件-->
<script setup>
import { scrollTo } from '@/utils/scrollTo';
import { computed } from 'vue';
const emits = defineEmits(['pagination', 'update:current', 'update:size']),
  props = defineProps({
    /**查询总条数 */
    total: { required: true, type: Number, default: 0 },
    /**当前页 */
    current: { type: Number, default: 1 },
    /**每页条数 */
    size: { type: Number, default: 10 },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    autoScroll: { type: Boolean, default: true },
    hidden: { type: Boolean, default: false }
  });

// eslint-disable-next-line one-var
let currentPage = computed({
    get: () => {
      return props.current;
    },
    set: (val) => {
      emits('update:current', val);
    }
  }),
  //每页查询长度
  pageSize = computed({
    get() {
      return props.size;
    },
    set(val) {
      emits('update:size', val);
    }
  });
//当前页码改变监听
// eslint-disable-next-line one-var, newline-after-var
const handleCurrentChange = (val) => {
    emits('pagination', { current: val, size: pageSize });
    if (props.autoScroll) {
      scrollTo(0, 800);
    }
  },
  //每页查询长度监听
  handleSizeChange = (val) => {
    emits('pagination', { current: currentPage, size: val });
    if (props.autoScroll) {
      scrollTo(0, 800);
    }
  };
</script>

<style lang="scss" scoped></style>
