<!--表格显示图片或根据内容生成二维码组件-->
<template>
  <!--preview-src-list:开启图片预览功能-->
  <el-image
    preview-teleported
    hide-on-click-modal
    close-on-press-escape
    fit="cover"
    :src="imageUrl"
    :preview-src-list="previewList"
    :style="{ width: props.width, height: props.height }"
  />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import QRCode from 'qrcode';

const fileUrl = process.env.VUE_APP_FILE_API, //图片显示路径前缀
  props = defineProps({
    type: {
      //类型 image:图片 QRCode: 二维码
      type: String,
      default: 'image'
    },
    width: {
      //图片宽度
      type: String,
      default: '35px'
    },
    height: {
      //图片高度
      type: String,
      default: '35px'
    },
    path: {
      //生成二维码的内容/图片地址
      type: String,
      default: null
    }
  });

//自定义变量
let imageUrl = ref(null), //显示的二维码地址
  previewList = ref([]); //图片预览数组

//生成二维码
// eslint-disable-next-line one-var
const makeQRCode = async (data) => {
  imageUrl.value = await QRCode.toDataURL(data);
  previewList.value.push(imageUrl.value);
};

onMounted(() => {
  if (props.path) {
    const type = typeof props.path[0];

    if ('QRCode' === props.type) {
      makeQRCode(props.path);
    } else if ('imageHead' === props.type) {
      if (props.path.indexOf('http') === -1) {
        imageUrl.value = fileUrl + props.path;
      } else {
        imageUrl.value = props.path;
      }
      previewList.value.push(imageUrl.value);
    } else if ('imagePicture' === props.type && type === 'object') {
      imageUrl.value = fileUrl + props.path[0].url;
      previewList.value.push(imageUrl.value);
    }
  }
});

watch(
  () => props.path,
  (newValue) => {
    if (newValue) {
      const type = typeof props.path[0];

      if ('QRCode' === props.type) {
        makeQRCode(props.path);
      } else if ('imageHead' === props.type) {
        imageUrl.value = fileUrl + props.path;
        previewList.value.push(imageUrl.value);
      } else if ('imagePicture' === props.type && type === 'object') {
        imageUrl.value = fileUrl + props.path[0].url;
        previewList.value.push(imageUrl.value);
      }
    }
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>

<style lang="scss" scoped></style>
