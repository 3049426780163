/* eslint-disable indent */
/* eslint-disable one-var */
/* eslint-disable new-cap */
import { Entity } from '@/model';
import { ElMessageBox, ElLoading, ElMessage } from 'element-plus';
const dataModel = new Entity({});
/**
   * @param{
   * data请求数据
   * api请求方法
   * isQueryData 是否传入请求参数
   * isDelNull 是否去除请求参数的空值
   * isLoading 是否loading
   * }
   * @type ,
   * @description queryloding设置 一般用于列表页面
   */

export async function queryLoding (data, api, isDelNull = true, isQueryData = true, isLoading = true) {
  let loading = null;

  if (isLoading) {
    loading = ElLoading.service({
      lock: true,
      text: '正在更新数据...',
      spinner: 'el-icon-loading',
      customClass: 'custom-class',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  }
  let res = null;

  if (isQueryData) {
    if (isDelNull) {
      res = await api(dataModel.delNull(data));
    } else {
      res = await api(data);
    }
  } else {
    res = await api();
  }
  if (isLoading && loading) {
    loading.close();
  }
  return res;
}
/**
   * @param{
   * data请求数据
   * title 提示
   * api 请求
   * }
   */
export async function confirmLoding (title, data, api) {
  let res = {
    success: false
  };

  await ElMessageBox.confirm(`${title}`, '操作提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const loading = ElLoading.service({
        lock: true,
        text: '正在更新数据...',
        spinner: 'el-icon-loading',
        customClass: 'custom-class',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      res = await api(data);
      if (res.success) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
          duration: 1000
        });
      }
      loading.close();
    })
    .catch(() => {
      ElMessage({
        message: '已取消操作!',
        type: 'info',
        duration: 1000
      });
    });
  return res;
}
/**
   * @param{
   * data请求数据
   * title 提示
   * api 请求
   * noNeedNullData null清除空数据
   * }
   */
export async function loding (data, api, noNeedNullData = true, title, isMessage = true) {
  let res = {
    success: false
  };
  const loading = ElLoading.service({
    lock: true,
    text: title ? title : '正在更新数据...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    customClass: 'custom-class'
  }),
    fromData = noNeedNullData ? dataModel.delNull(data) : data;

  res = await api(fromData);
  if (res.success) {
    if (isMessage) {
      ElMessage({
        message: '操作成功',
        type: 'success',
        duration: 1000
      });
    }
  }
  loading.close();
  return res;
}
