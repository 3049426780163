/** 查询条件表 */
import Model from 'js-model';
export class Entity extends Model {
  parse (data) {
    return super.parse(data);
  }

  dispose (data) {
    const newData = {};

    for (const key in data) {
      if (data[key] !== []) {
        newData[key] = data[key];
      }
    }
    if (newData.data) {
      const queryData = {};

      for (const key in data.data) {
        if (Boolean(newData.data[key]) || newData.data[key] === 0) {
          queryData[key] = newData.data[key];
        }
      }
      newData.data = { ...queryData };
    }
    return super.dispose(newData);
  }
}
const queryEntity = new Entity({
  // 当前页码
  page: {
    type: Number,
    default: 1
  },
  // 每页的数量,示例值(10)
  pageSize: {
    type: Number,
    default: 10
  },
  // table 高度
  tableHeight: {
    type: Number,
    default: 500
  },
  //  查询条件对象
  queryEntity: {},
  // 升序排序字段(例:a,b)
  ascOrderBy: {
    type: String,
    default: null
  },
  // 降序排序字段(例:a,b)
  descOrderBy: {
    type: String,
    default: null
  },
  // 数据总条数
  total: {
    type: Number,
    default: 0
  },
  // 加载提示
  loading: {
    type: Boolean,
    default: false
  }

});

export default queryEntity;
