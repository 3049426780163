// 范围日期选择配置
const options = {
  picker: {
    shortcuts: [{
      text: '最近一周',
      onClick (picker) {
        const end = new Date(),
          start = new Date();

        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近一个月',
      onClick (picker) {
        const end = new Date(),
          start = new Date();

        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近三个月',
      onClick (picker) {
        const end = new Date(),
          start = new Date();

        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  },
  // 日期格式
  dateFormat: {
    date: 'YYYY-MM-DD',
    dateAndTime: 'YYYY-MM-DD HH:mm:ss',
    time: 'HH:mm:ss',
    week: 'yyyy 第 WW 周',
    year: 'YYYY',
    yearMonth: 'YYYY-MM',
    month: 'M'
  },

  // 文件上传黑名单
  fileUpBlackList: ['.js', '.vbs', '.exe', '.bat', '.html', '.xml'],
  // 文件上传白名单
  fileUpWhiteList: ['.jpg', '.jpeg', '.png', '.bmp', '.doc', '.docx', '.pdf', '.xls', '.xlsx', '.zip', '.rar', '.7z']
};

export default options;
