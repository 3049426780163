<!-- 数据字典下拉选择框 -->
<template>
  <el-select
    v-model="val"
    :readonly="readonly"
    :size="size"
    :disabled="disabled"
    :multiple="multiple"
    :style="{ width: width }"
    :clearable="clearable"
    filterable
    :class="className"
    :placeholder="placeholder"
  >
    <template v-for="(item, key) in list">
      <template v-if="needVlas.length > 0">
        <el-option
          v-if="undefined !== needVlas.find((r) => r === item[valueName])"
          :key="key"
          :label="item.name"
          :value="item[valueName]"
        />
      </template>
      <template v-else>
        <el-option
          v-if="undefined === noNeedVals.find((r) => r === item[valueName])"
          :key="key"
          :label="item.name"
          :value="item[valueName]"
        />
      </template>
    </template>
  </el-select>
</template>
<script setup>
import { ref, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
const store = useStore(),
  emits = defineEmits(['change', 'selectChange']),
  props = defineProps({
    clearable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    needVlas: {
      type: Array,
      default: () => []
    },
    noNeedVals: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Array],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    valueName: {
      type: String,
      default: 'value'
    },
    //管理员编码过滤
    adminCode: {
      type: String,
      default: null
    }
  }),
  val = ref(),
  allList = ref([]), //查询完整数组
  list = ref([]);

// eslint-disable-next-line one-var
const initData = async () => {
  let dictionaryList = store.getters.getSysDictList(props.code);

  if (Boolean(dictionaryList) && dictionaryList.length > 0) {
    allList.value = dictionaryList;
  } else {
    const { data } = await store.dispatch('GetSysDictList', props.code);

    allList.value = data;
  }
  if (props.adminCode) {
    list.value = allList.value.filter(
      (element) => element.adminCode === props.adminCode
    );
  } else {
    list.value = allList.value;
  }
};

onBeforeMount(() => {
  initData();
});

//监听函数
watch(
  () => props.value,
  (newValue) => {
    val.value = newValue;
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

watch(
  () => val.value,
  (newValue) => {
    if (newValue && list.value && list.value.length > 0) {
      emits(
        'selectChange',
        list.value.find((r) => r.value === newValue.value)
      );
    }
    emits('change', newValue ? newValue.value : null);
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

//机构编码过滤
watch(
  () => props.adminCode,
  (newValue) => {
    if (newValue && allList.value && allList.value[0]) {
      list.value = allList.value.filter(
        (element) => element.adminCode === newValue
      );
    } else {
      list.value = allList.value;
    }
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
