<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div>
    <el-image
      style="
         {
          width: props.width;
          height: props.height;
        }
      "
      :src="signature"
      :fit="fit"
    />
    <vue-esign
      v-if="props.visible"
      ref="esign"
      v-model:bgColor="props.bgColor"
      :width="props.width"
      :height="props.height"
      :is-crop="isCrop"
      :line-width="lineWidth"
      :line-color="lineColor"
      :is-clear-bg-color="false"
    />
    <div v-if="props.visible" class="button">
      <el-button @click="handleReset"> 清空画板 </el-button>
      <el-button @click="handleGenerate">生成图片</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { ElNotification } from 'element-plus';

const emits = defineEmits(['update:modelValue', 'change']),
  props = defineProps({
    modelValue: {
      //图片回显地址
      type: String,
      default: ''
    },
    //是否显示
    visible: { type: Boolean, default: true },
    bgColor: { type: String, default: 'gray' },
    //画笔颜色
    lineColor: { type: String, default: '#000000' },
    //线宽
    lineWidth: { type: String, default: '4' },
    //是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
    isCrop: { type: Boolean, default: false },
    //画布宽度，即导出图片的宽度
    width: { type: String, default: '620' },
    //画布高度，即导出图片的高度
    height: { type: String, default: '150' }
  });

let esign = ref(),
  signature = ref(props.src);

// eslint-disable-next-line one-var
const handleReset = () => {
    esign.value.reset();
    signature.value = null;
  },
  handleGenerate = () => {
    esign.value
      .generate({ format: 'image/jpeg', quality: 0.5 })
      .then((res) => {
        signature.value = res;
      })
      .catch((err) => {
        ElNotification.error('获取签名失败');
      });
  };

//监听函数
watch(
  () => props.modelValue,
  (newValue) => {
    signature.value = newValue;
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//监听函数
watch(
  () => signature.value,
  (newValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>

<style lang="scss" scoped>
.button {
  margin-top: 10px;
  text-align: center;
}
</style>
