<!-- eslint-disable one-var -->
<!-- 数据字典单选框 -->
<template>
  <el-radio-group
    v-model="val"
    :text-color="'#3EA88D'"
    fill="#3EA88D"
    :disabled="disabled"
    @change="change"
  >
    <template v-for="(item, key) in list">
      <el-radio
        v-if="undefined === noNeedVals.find((r) => r === item[valueName])"
        :key="key"
        :text-color="'#3EA88D'"
        :label="item[valueName]"
      >
        {{ item.name }}
      </el-radio>
    </template>
  </el-radio-group>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

//定义属性
let val = ref(''),
  list = ref([]);

const store = useStore(),
  emits = defineEmits(['change']),
  props = defineProps({
    noNeedVals: {
      type: Array,
      default: () => []
    },
    code: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    valueName: {
      type: String,
      default: 'value'
    }
  }),
  // eslint-disable-next-line one-var
  initData = async () => {
    let dictionaryList = store.getters.getSysDictList(props.code);

    if (Boolean(dictionaryList) && dictionaryList.length > 0) {
      list.value = dictionaryList;
    } else {
      const { data } = await store.dispatch('GetSysDictList', props.code);

      list.value = data;
    }
  },
  //值变化监听
  change = () => {
    emits('change', val);
  };

onBeforeMount(() => {
  initData();
});
</script>
