/* eslint-disable one-var */
export const TokenKey = 'Authorization'; //登录
export const UserKey = 'user'; //用户

import ase from '@/utils/ase';

export function getToken () {
  const token = localStorage.getItem(ase.encrypt(TokenKey));

  if (token) {
    return ase.decrypt(token);
  }
  return token;

}

export function setToken (token) {
  return localStorage.setItem(ase.encrypt(TokenKey), ase.encrypt(token));
}

export function removeToken () {
  return localStorage.removeItem(ase.encrypt(TokenKey));
}


/**用户信息 */
export function getUser () {
  const list = localStorage.getItem(ase.encrypt(UserKey));

  if (list) {
    return JSON.parse(ase.decrypt(list));
  }
  return list;
}

export function setUser (list) {
  return localStorage.setItem(ase.encrypt(UserKey), ase.encrypt(JSON.stringify(list)));
}

export function removeUser () {
  return localStorage.removeItem(ase.encrypt(UserKey));
}
