import request from '@/utils/request';
/** 分页查询(超管) */
export function dictionaryPageInfo (data) {
  return request({
    url: `/sys/dictionary/page`,
    method: 'post',
    data: data
  });
}
/** 查询列表 */
export function dictionaryQueryList (data) {
  return request({
    url: `/sys/dictionary/queryList`,
    method: 'post',
    data: data
  });
}
/** 保存 */
export function dictionarySave (data) {
  return request({
    url: `/sys/dictionary/save`,
    method: 'post',
    data: data
  });
}

/** 删除 */
export function dictionaryDelete (id) {
  return request({
    url: `/sys/dictionary/delete/${id}`,
    method: 'get'
  });
}

/** 根据条件分页查询数据字典(系统管理员数据) */
export function dictionaryPageInfoX (data) {
  return request({
    url: `/sys/dictionary/pageInfo`,
    method: 'post',
    data: data
  });
}

