<template>
  <div id="app">
    <router-view class="app-router-view" />
  </div>
</template>

<script>
import { provide, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as echarts from 'echarts';
import DevicePixelRatio from '@/utils/evicePixelRatio.js';
import { getUser } from '@/utils/auth';

import { getSzLoginUserInfo } from '@/api/sys/login';

export default {
  name: 'App',
  setup() {
    provide('echarts', echarts); //provide
    const store = useStore(),
      router = useRouter(),
      { proxy } = getCurrentInstance(),
      bodyScale = () => {
        var devicewidth = document.documentElement.clientWidth, //获取当前分辨率下的可是区域宽度
          scale = devicewidth / 1920; // 分母——设计稿的尺寸

        document.body.style.zoom = scale; //放大缩小相应倍数
      };

    // eslint-disable-next-line no-undef
    onMounted(async () => {
      //解决分辨率不是100%的屏幕变形问题
      new DevicePixelRatio().init();
      bodyScale();
      //晟灼统一平台登录
      // 获取URL参数
      const params = new URLSearchParams(window.location.search);

      if (params && params.get('sz')) {
        // 获取对象参数并解码
        const token = params.get('sz');

        proxy.$apiLoding
          .queryLoding({ token: token }, getSzLoginUserInfo)
          .then((res) => {
            if (res.success && res.data) {
              //用户信息设置到Vuex中
              store.dispatch('user/setUserInfo', res.data);
              router.push({ path: '/echarts/safetyPicket' });
            }
          });
      } else {
        //本地项目登录
        //获取用户信息，被设置到Vuex中
        const userInfo = getUser();

        if (userInfo && userInfo.menuList) {
          await store.dispatch('user/setUserInfo', userInfo);
          await router.push({ path: '/echarts/safetyPicket' });
        } else {
          router.push(`/register`);
        }
      }
    });
  }
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.app-router-view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
</style>
